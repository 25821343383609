* {
    font-family: "all-round-gothic", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.header {
    background-color: rgba(41, 35, 92);
    display: flex;
    align-items: stretch;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
    padding: 0 20px;
    justify-content: space-between;
}

.center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
} 

.header::after {
    content: '';
    display: table;
    clear: both;
}

.logo {
    float: left;
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.flexFiller {
    flex-grow: 1;
}

nav {
    display: flex;
    align-items: center;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav li {
    display: inline-block;
    margin-left: 40px;

}

nav a {
    color: white;
    text-decoration: none;
    padding-top: 25px;
    transition: 0.5s;
}

@media screen and (max-width: 930px) {

    .header {
        padding: 20px;
    }

    nav a {
        display: inline;
    }

  }


/* nav a:hover::after {
    width: 100%;
}

nav a::after {
content: '';
display: block;
width: 0;
height: 2px;
background-color: white;
transition: .1s;
} */

#support a::after {
    display: none;
}

@media (max-width: 375px) {
    nav li {
        margin-right: 10px;
    }
}