.footer {
    color: var(--color-primary);
    height: 88px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
}

.footer .container {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    margin-left: 10px;
}

.container .media {
    /* text-decoration: underline; */
    padding-bottom: .5em;
    border-bottom: 1px solid var(--color-primary);
    width: 300px;
    margin: 0;
}

.container .all-medias .icons {
    list-style-type: none;
    transition: transform .2s;                                                                                                                                                                             ;
}

.container .all-medias .icons:hover {
    transform: scale(1.5);
}


.container .all-medias {
    padding-left: 0;
    display: flex;
    margin-bottom: 0;
}

.container .all-medias .icons a {
    text-decoration: none;
    margin-right: 10px;
    color: var(--color-primary);
    transition: 0.2s;
}
