.songs {
    position: relative;
}

.cards {
    /* margin: 0 auto; */
    margin: 50px 100px 50px 100px;
}

#first-card {
    padding-top: 50px;
}

.all {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 930px) {
    .form {
        width: 350px;
    }
    
  }

  @font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(/public/fonts/Outfit-Regular.ttf);
  }



.artwork {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
}

.artwork:hover {
    box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45), 25px 0 20px -20px rgba(0, 0, 0, 0.45);
}


.info {
    font-family: 'Outfit Light', sans-serif;
    max-width: 500px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8; 
    white-space: pre-wrap; 
}

.title {
    font-family: 'Outfit Bold', sans-serif;
}

.title, .info {
    color: rgb(41, 35, 92);
    margin-left: 50px;
}



