@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(/public/fonts/Outfit-Regular.ttf);
  }

:root {
    --color-primary: black;
}

html, body {
    background-color: rgb(242, 145, 0);
    font-family: "Outfit";
}

.vid-page, #intro-page {
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 930px) {

    .vid-page, #intro-page {
        display: none;
    }

}

.top-tracks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.left-arrow, .right-arrow {
    cursor: pointer;
}

.arrows {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px;
}

@keyframes bounceGrow {
    0%, 20%, 50%, 80%, 100% {
      transform: scaleY(1) scaleX(1);
    }
    40% {
      transform: scaleY(1.2) scaleX(1.2);
    }
    60% {
      transform: scaleY(0.8) scaleX(0.8);
    }
  }

.left-arrow:hover, .right-arrow:hover {
    animation: bounceGrow 2s infinite;
}

/* Playlist Group Section */

.playlists {
    /* background-color: #101010; */
}

.playlists .title {
    text-align: center;
    padding-top: 16px;

}

.playlists .card-group {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;
}

/* Listen Now Section */

.listen-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}


.frame {
    margin-bottom: 25px;
}

/* Footer */

footer {
    background-color: rgb(34, 34, 34, 0.6);
    color: #fff;
    position: absolute;
    width: 100%;
}

footer .container {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    margin-left: 10px;
}

.container .media {
    /* text-decoration: underline; */
    padding-bottom: .5em;
    border-bottom: 1px solid var(--color-primary);
    width: 300px;
}

.container .all-medias .icons {
    list-style-type: none                                                                                                                                                                               ;
}

.container .all-medias {
    padding-left: 0;
    display: flex;
}

.player {
    border-radius:12px;
}

.view-videos {
    border: solid black;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
    color: black;
    letter-spacing: 6px;
}

@media (max-width: 800px) {
    #ytplayer {
        width: 100%;
        max-width: 640px;
    }
    .top-tracks {
        display: block;
    }

    .card-group {
        display: flex;
        flex-direction: column;
    }
    /* .playlists {
        display: flex;
        flex-direction: column;
    } */
}

@media (max-height: 500px) {
    
    .top-tracks {
        justify-content: center;
    }
}