.sm-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 200px;
    align-items: center;
}

@media screen and (max-width: 930px) {

    .sm-icons {
        display: none;
    }

}

#youtube, #instagram, #twitter {
    width: 55px;
    height: 55px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
}

#youtube {
    background-image: url("/public/images/youtube.svg");
}

#instagram {
    background-image: url("/public/images/instagram.svg");
}

#twitter {
    background-image: url("/public/images/twitterx.svg");
}