.return-home {
    position: absolute;
    /* bottom: 0px; */
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: #536872;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    transition: 1s;
    margin-bottom: 5px;
    transform: translate(-50%, 0);
 }
 
 .return-home:hover {
     opacity: 0.6;
 }
 
 .arrow {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 @media screen and (max-width: 930px) {

    .return-home {
        display: none;
    }

  }