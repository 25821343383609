body {
  margin: 0 auto;
  background-color: #000000;
  color: white;
  font-family: 'Outfit', sans-serif;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
